<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Senior call settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">

            <div class="card card--table">
              <div class="card-header d-flex align-items-center justify-content-between">
                <div>
                  <h4><strong>{{ dataTotal }}</strong> results</h4>
                </div>
                <div>
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Open filters"
                  ><a
                    class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasFilters"
                    aria-controls="offcanvasFilters"
                    @click="openFilters"
                  ><i data-feather="filter" /></a></span>
                </div>
              </div>
              <div
                id="usedFilters"
                class="card-body"
              >
                Applied Filters:
                <span
                  v-for="filter, index in filters"
                  :key="index"
                  class="badge badge-light-dark ms-1"
                >
                  <template v-if="Array.isArray(filter)">
                    <template
                      v-for="(str, i) in filter"
                    >
                      {{ str.section_format }}
                      <a
                        :key="str.name"
                        class="ms-1 text-black"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Delete filter"
                        @click="deleteFilter('categories', i)"
                      ><i data-feather="x" /></a>
                    </template>
                  </template>
                  <template v-else-if="typeof filter === 'object' && filter">
                    {{ filter.menu_format }}
                    <a
                      class="ms-1 text-black"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Delete filter"
                      @click="deleteFilter('menu')"
                    ><i data-feather="x" /></a>
                  </template>
                  <template v-else-if="filter">
                    {{ filter }}
                    <a
                      class="ms-1 text-black"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Delete filter"
                      @click="deleteFilter('name')"
                    ><i data-feather="x" /></a>
                  </template>
                </span>
              </div>
            </div>
            <div v-if="labels">
              <CardLabelEdit
                v-for="label in auxLabels"
                :key="label.id"
                :label="label"
                @onSaveButon="saveData($event)"
              /></div>
          </div>
        </div>

        <ItemInfoSheet :table-id="filterInfoSheet">
          <template #info-sheet-title>
            Search & Filters
          </template>
          <template
            #info-sheet
          >
            <div class="offcanvas-body">
              <div class="mb-1">
                <label
                  for="name"
                  class="form-label required"
                >Name</label>
                <input
                  type="text"
                  class="form-control"
                  name="name"
                  @change="saveFilter($event.target.value, 'name')"
                >
              </div>
              <hr>
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >Menu</label>
                <v-select
                  label="menu_format"
                  :options="menus"
                  :get-option-key="option => option.menu"
                  @input="saveFilter($event, 'menu')"
                />
              </div>
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >Categories</label>
                <v-select
                  label="section_format"
                  multiple
                  :options="categories"
                  :get-option-key="option => option.section"
                  @input="saveFilter($event, 'categories')"
                />
              </div>
            </div>
            <div class="offcanvas-footer mt-auto">
              <button
                type="button"
                class="btn btn-dark mb-1 d-grid w-100"
                @click="applyFilters"
              >
                Apply
              </button>
            </div>
          </template>
        </ItemInfoSheet>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import ItemInfoSheet from '@/views/back/partials/offcanvas/ItemInfoSheet.vue'
import vSelect from 'vue-select'
import CardLabelEdit from './components/CardLabelEdit.vue'

export default {
  components: {
    ItemInfoSheet,
    CardLabelEdit,
    vSelect,
  },
  props: {
    prefilter: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      title: 'Search result',
      results: 0,
      filterInfoSheet: 1,
      filters: {
        name: null,
        menu: null,
        categories: [],
      },
      auxFilters: {
        name: null,
        menu: null,
        categories: [],
      },
      auxLabels: [],
    }
  },
  computed: {
    ...mapGetters({
      categories: 'sectionLabels/itemCategories',
      menus: 'sectionLabels/itemMenus',
      loggedUser: 'auth/admin',
      labels: 'sectionLabels/item',
      dataTotal: 'sectionLabels/itemTotal',
    }),
  },
  watch: {
    labels() {
      this.auxLabels = Object.keys(this.labels).map(key => this.labels[key])
    },
  },
  async mounted() {
    await this.$store.dispatch('sectionLabels/fetchCategories')
    await this.$store.dispatch('sectionLabels/fetchMenus')
    if (this.prefilter) {
      this.filters = { ...this.prefilter }
      await this.filterData()
    }
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    openFilters() {
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
      this.$store.dispatch('modals/toggleTableClicked', this.filterInfoSheet)

      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    async applyFilters() {
      this.$store.dispatch('modals/toggleTableClicked', this.filterInfoSheet)
      this.$store.dispatch('modals/toggleInfoItemSheet', false)

      this.filters = { ...this.auxFilters }

      await this.filterData()
    },
    async saveFilter(value, filter) {
      switch (filter) {
        case 'categories':
          if (value) this.auxFilters.categories = value
          else this.auxFilters.categories = []
          break
        case 'menu':
          if (value) {
            this.auxFilters.menu = value
            await this.$store.dispatch('sectionLabels/fetchCategories', value.menu)
          } else {
            this.auxFilters.menu = null
            await this.$store.dispatch('sectionLabels/fetchCategories')
          }
          break
        case 'name':
          if (value) this.auxFilters.name = value
          else this.auxFilters.name = null
          break
        default:
          break
      }
    },
    async deleteFilter(filter, index = null) {
      switch (filter) {
        case 'categories':
          this.filters.categories.splice(index, 1)
          this.auxFilters.categories.splice(index, 1)
          break
        case 'menu':
          this.filters.menu = null
          this.auxFilters.menu = null
          await this.$store.dispatch('sectionLabels/fetchCategories')
          break
        case 'name':
          this.filters.name = null
          this.auxFilters.name = null
          break
        default:
          break
      }

      await this.filterData()
    },
    async saveData(label) {
      try {
        await this.$store.dispatch('sectionLabels/updateItem', {
          data: label,
        })
        await this.$store.dispatch('sectionLabels/filterSearch', this.filters)
      } catch (e) {
        // this.errors = e.response.data.message
        Vue.swal(`Error on save label! ${e.response.data.message}`, '', 'error')
      }
    },
    async filterData() {
      if (this.filters.name != null || this.filters.categories.length !== 0 || this.filters.menu?.menu !== null) {
        this.$store.dispatch('sectionLabels/filterSearch', this.filters)
      } else {
        await this.$store.dispatch('sectionLabels/clearItem')
      }

      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
  },
}
</script>
